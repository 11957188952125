import {inject, Injectable, Injector, isDevMode, NgZone} from '@angular/core';
import {AngularFireAnalytics} from '@angular/fire/compat/analytics';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {
  animationFrameScheduler,
  catchError,
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  filter,
  map,
  Observable,
  retry,
  shareReplay,
  skipUntil,
  startWith,
  Subject,
  subscribeOn,
  switchMap,
  tap,
  timer,
  withLatestFrom,
} from 'rxjs';
import {DbSessionAnalyticsMetrics, metricsCollectionPath} from 'terrific-shared/analytic/metrics';
import {
  AnyClientEvent,
  SessionEventDataTypes,
  StoreEventDataTypes,
} from '../../../../shared/analytic/events';
import {
  ANALYTICS_BIGQUERY,
  DbSessionAnalyticsCountersDoc,
  PollStatus,
} from '../../../../shared/db-models/session';
import {
  EventName as OldEventName,
  PollEvents,
  SessionEventData,
  ShareEvents,
} from '../../../../shared/db-models/session-events';
import {SessionDataDTO} from '../../../../shared/dto-models/session-data';
import TimestampHelper from '../helpers/timestamp-helper';
import {ActiveStoreModel} from '../interfaces/store-models';
import {LogService} from '../logger/logger.service';
import {SessionService} from '../session/shared/session.service';
import {UsersService} from './users.service';
import {CookieService} from './cookie.service';

declare global {
  interface Window {
    fbq?: (param1: string, param2: any, param3?: any) => any;
    _fbq?: unknown;
  }
}

function initPixel(window: Window, document: Document, script: 'script', scriptUrl: string) {
  if (window.fbq as any) return;

  const facebookAnalytics: any = (window.fbq = function tempFnForEventsEmitted(...args) {
    facebookAnalytics.callMethod
      ? facebookAnalytics.callMethod(facebookAnalytics, ...args)
      : facebookAnalytics.queue.push(args);
  });

  if (!window._fbq) window._fbq = facebookAnalytics;

  facebookAnalytics.push = facebookAnalytics;
  facebookAnalytics.loaded = !0;
  facebookAnalytics.version = '2.0';
  facebookAnalytics.queue = [];

  const scriptElement = document.createElement(script);
  scriptElement.async = !0;
  scriptElement.src = scriptUrl;

  const firstScriptOnPage = document.getElementsByTagName(script)[0];
  firstScriptOnPage.parentNode?.insertBefore(scriptElement, firstScriptOnPage);
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  injector = inject(Injector);
  private analytics = inject(AngularFireAnalytics);
  private angularFireFunctions = inject(AngularFireFunctions);
  private firestore = inject(AngularFirestore);
  private ngZone = inject(NgZone);
  private logService = inject(LogService);
  private cookieService = inject(CookieService);

  public addPixelId = (pixelId: string) => {
    if (pixelId.length > 0) {
      this.ngZone.runOutsideAngular(() => {
        initPixel(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        window.fbq?.('init', pixelId);
        window.fbq?.('track', 'PageView');
      });
    }
  };

  private get user() {
    return this.injector.get(UsersService).connectedUserSync;
  }

  private get sessionService() {
    return this.injector.get(SessionService);
  }

  private get usersService() {
    return this.injector.get(UsersService);
  }

  public logEvent = (
    eventName: string,
    eventParams?: Record<string, any> | undefined,
    options?: any
  ) => {
    return Promise.allSettled([
      this.analytics.logEvent(eventName, eventParams, options).then(
        () => this.logService.log(eventName + ' analytics log event sended successfully to server'),
        (e) => {
          this.logService.error('analytics service ~ analytics.logEvent', e);
        }
      ),
      window.fbq?.(eventName, eventParams, options),
      window.fbq?.('track', eventName),
    ]);
  };

  public createShareSessionEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: OldEventName.ShareSessionEvent, sessionId, linkId});
  }

  public createShareProductEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: OldEventName.ShareProductEvent, sessionId, linkId});
  }

  public createUseShareSessionEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: OldEventName.UseShareSessionEvent, sessionId, linkId});
  }

  public createUseShareProductEvent(sessionId: string, linkId: string) {
    return this.createShareEvent({name: OldEventName.UseShareProductEvent, sessionId, linkId});
  }

  public createShareEvent({
    name,
    sessionId,
    linkId,
  }: {
    name: ShareEvents;
    sessionId: string;
    linkId: string;
  }) {
    const event = {
      name: name,
      userId: this.user.uid,
      sessionId,
      linkId,
    };
    return this.analytics_createSessionEvent(event);
  }

  public createPollEvent(
    sessionId: string | undefined,
    data: {
      name: PollEvents;
      pollId: string;
      pollState?: PollStatus;
      answerId?: string;
      itemName?: string;
      productId?: string;
    }
  ): void {
    if (sessionId) {
      const event = {
        sessionId,
        userId: this.user.uid,
        ...data,
      };

      if (isDevMode()) {
        console.log('poll analytics:', event);
      }

      this.analytics_createSessionEvent(event);
    }
  }

  public getSessionAnalyticsCounters(session: SessionDataDTO) {
    return this._getSessionAnalyticsCounters(session);
  }

  private _getSessionAnalyticsCounters(session: SessionDataDTO) {
    return this._getCounterFromPath(`sessions/${session.id}/analytics/counters`).pipe(
      shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler})
    );
  }

  private _getCounterFromPath(path: string) {
    const counters = this.firestore
      .doc<DbSessionAnalyticsCountersDoc>(path)
      .valueChanges()
      .pipe(shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler}));
    return counters.pipe(map((counters) => counters ?? {}));
  }

  public getSessionAnalyticsMetrics(sessionId: string) {
    return this._getSessionAnalyticsMetrics(sessionId);
  }

  private _getSessionAnalyticsMetrics(sessionId: string) {
    return this._getMetricsFromPath(metricsCollectionPath(sessionId)).pipe(
      shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler})
    );
  }

  private _getMetricsFromPath(path: string) {
    const counters = this.firestore
      .doc<DbSessionAnalyticsMetrics>(path)
      .valueChanges()
      .pipe(shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler}));
    return counters.pipe(map((metrics) => metrics ?? {}));
  }

  getSessionOrders(session: SessionDataDTO) {
    return this.sessionService.getSessionOrders(session);
  }

  // TODO: remove after migration to 'Analytics on BigQuery'
  public analytics_createSessionEvent<T extends SessionEventData>(data: T & {sessionId: string}) {
    const subject = new Subject<void>();

    const observable = this.angularFireFunctions
      .httpsCallable<T, void>('analytics_createSessionEvent')(data)
      .pipe(
        tap(() => {
          try {
            this.logService.log('analytics service ~ analytics_createSessionEvent ~ created', data);
            this.logEvent(data.name, data);
          } catch (error) {
            this.logService.error(
              'analytics service ~ analytics_createSessionEvent ~ error',
              error
            );
          }
        }),
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler}),
        subscribeOn(animationFrameScheduler)
      );

    observable.subscribe(subject);

    return subject;
  }

  public postSessionEvent<T extends keyof SessionEventDataTypes>(
    name: T,
    sessionId: string,
    data: SessionEventDataTypes[T]
  ) {
    const event: AnyClientEvent = {
      name,
      sessionId,
      ...data,
    };
    return this.postEvent(event);
  }

  public postStoreEvent<T extends keyof StoreEventDataTypes>(
    name: T,
    storeId: string,
    data: StoreEventDataTypes[T]
  ) {
    const event: AnyClientEvent = {
      name,
      storeId,
      ...data,
    };
    return this.postEvent(event);
  }

  private async postEvent(event: AnyClientEvent) {
    const subject = new Subject<void>();
    let guestUserId = this.cookieService.getCookie('guestUserId');
    if (!guestUserId) {
      guestUserId = this.usersService.generateGuestUID();
      this.cookieService.setCookie('guestUserId', guestUserId, 365);
    }
    event = {...event, 'guestUserId': guestUserId};
    const observable = this.angularFireFunctions
      .httpsCallable<AnyClientEvent, void>('analytics_postSessionEvent')(event)
      .pipe(
        tap(() => {
          try {
            this.logService.debug(
              'analytics service ~ analytics_postSessionEvent ~ created',
              event
            );
            this.logEvent(event.name, event);
          } catch (error) {
            this.logService.error('analytics service ~ analytics_postSessionEvent ~ error', error);
          }
        }),
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        shareReplay({refCount: true, bufferSize: 1, scheduler: animationFrameScheduler}),
        subscribeOn(animationFrameScheduler)
      );

    observable.subscribe(subject);
    return subject;
  }

  public startHeartbeat(
    session$: Observable<SessionDataDTO | null | undefined>,
    isHost$: Observable<boolean>,
    store$: Observable<ActiveStoreModel | null | undefined>
  ) {
    return combineLatest({
      isAdmin: store$.pipe(
        map((store) => store?.isManager),
        startWith(false)
      ),
      isHost: isHost$.pipe(startWith(false)),
    }).pipe(
      skipUntil(
        session$.pipe(
          filter((x): x is SessionDataDTO => !!x),
          filter((x) => x.analyticsVersion === ANALYTICS_BIGQUERY)
        )
      ),
      map(({isAdmin, isHost}) => isAdmin || isHost),
      distinctUntilChanged(),
      withLatestFrom(session$.pipe(filter((x): x is SessionDataDTO => !!x))),
      switchMap(([isSpecialUser, session]) => {
        if (isSpecialUser) {
          return timer(0, 30 * 1000).pipe(map(() => session));
        } else {
          return EMPTY;
        }
      }),
      switchMap((session) => {
        return this.heartbeat(session.id);
      }),
      catchError((error) => {
        this.logService.error('analytics service ~ startHeartbeat ~ error', error);
        return EMPTY;
      })
    );
  }

  /**
   *
   * @param sessionId
   */
  public synchronizeAnalyticsNow(sessionId: string): Observable<unknown> {
    return this.angularFireFunctions
      .httpsCallable('analytics_runSessionMetricsFetch')({sessionId})
      .pipe(
        catchError((error) => {
          this.logService.error('analytics service ~ synchronizeAnalyticsNow ~ error', error);
          return EMPTY;
        }),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  private heartbeat(sessionId: string) {
    return this.firestore
      .collection('sessions')
      .doc(sessionId)
      .collection('sessionAnalyticsCalls')
      .doc(sessionId)
      .set({timestamp: TimestampHelper.getCurrentDateTimestamp()});
  }
}
