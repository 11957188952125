import {inject, Injectable, OnDestroy} from '@angular/core';
import {
  AngularFirestore,
  DocumentChangeAction,
  QuerySnapshot,
} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {DatabaseReference} from '@angular/fire/database';
import {Timestamp} from '@angular/fire/firestore';
import {combineLatest, EMPTY, from, NEVER, Observable, of, Subject, throwError} from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  retry,
  share,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  throttleTime,
} from 'rxjs/operators';
import {CacheAbleShare} from 'src/app/helpers/cache.decoretor';
import {recursiveMapOperator} from 'src/app/helpers/recursive-map.rxjs';
import {ActiveStoreModel} from 'src/app/interfaces/store-models';
import {LanguageService} from 'src/app/language.service';
import {LogService} from 'src/app/logger/logger.service';
import {AppService} from 'src/app/services/app.service';
import {CartService} from 'src/app/services/cart.service';
import {LocalStorageService} from 'src/app/services/local-storage.service';
import {NavigateService} from 'src/app/services/navigate.service';
import {PollsService} from 'src/app/services/polls.service';
import {StateHolderService} from 'src/app/services/state-holder.service';
import {StorageService} from 'src/app/services/storage.service';
import {StoresService} from 'src/app/services/stores.service';
import {UsersService} from 'src/app/services/users.service';
import type {DbCartModel} from '../../../../../shared/db-models/cart';
import type {DbOrderModel} from '../../../../../shared/db-models/order';
import {
  DbCouponsDocModel,
  DbParentMessage,
  DbSessionBroadcastTokenModel,
  DbSessionLikeModel,
  DbSessionLiveDataModel,
  DbSessionMessageModel,
  DbSessionModel,
  DbSessionPromoVideoModel,
} from '../../../../../shared/db-models/session';
import {DbStoreShippingMethodModel, SendReminderData} from '../../../../../shared/db-models/store';
import {
  SessionChatModerationDTO,
  SessionDataDTO,
  SessionDataProductDTO,
  SessionDataProductVariantDTO,
  SessionSeatDTO,
} from '../../../../../shared/dto-models/session-data';
import {SessionState} from '../../../../../shared/types/session';
import {AffiliateRegexModel} from '../../../../../shared/utilities/affilaite';
import TimestampHelper from '../../helpers/timestamp-helper';
import {autoLog} from '../../logger/auto-log.decorator';
import {SessionRtdbService} from '../rtdb/rtdb.service';
import {increment} from '@angular/fire/firestore';
import {CookieService} from 'src/app/services/cookie.service';

@Injectable({
  providedIn: 'root',
})
export class SessionService implements OnDestroy {
  /**
   * Returns an observable that emits the session recordings for a given session ID.
   *
   * @param id The ID of the session.
   * @returns An observable that emits the session recordings for a given session ID.
   */

  private readonly destroyed$ = new Subject();
  private firestore = inject(AngularFirestore);
  private fns = inject(AngularFireFunctions);
  private usersService = inject(UsersService);
  private cookieService = inject(CookieService);
  private languageService = inject(LanguageService);
  private cartService = inject(CartService);
  private storeService = inject(StoresService);
  private storageService = inject(StorageService);
  private appService = inject(AppService);
  private stateService = inject(StateHolderService);
  private navigationService = inject(NavigateService);
  private localStoreService = inject(LocalStorageService);
  private pollsService = inject(PollsService);
  private rtdb = inject(SessionRtdbService);
  public logService = inject(LogService);

  getSessionRecordings(session: DbSessionModel) {
    return this.storageService
      .listAllFilesInDirectory(`sessionRecordings/${session.storeId}/${session.id}`, [
        'mp4',
        // 'm3u8',
      ])
      .pipe(switchMap(async (files) => Promise.all(files.map((file) => file.getDownloadURL()))));
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  getAffiliateRegexModel(
    store: Observable<ActiveStoreModel | null>
  ): Observable<AffiliateRegexModel | undefined> {
    return store.pipe(
      takeUntil(this.destroyed$),
      switchMap((_store) =>
        this.firestore
          .collection<AffiliateRegexModel>(`affiliateRegex`)
          .doc(_store?.id)
          .valueChanges()
      ),
      retry({count: 3, delay: 300, resetOnSuccess: true})
    );
  }

  public getSessionCarts(sessionId: string): Observable<DocumentChangeAction<DbCartModel>[]> {
    return this.firestore
      .collection<DbCartModel>('carts', (ref) =>
        ref.where('sessionId', '==', sessionId).where('isOpen', '==', true)
      )
      .snapshotChanges();
  }

  public getSessionOrders(
    session: SessionDataDTO
  ): Observable<DocumentChangeAction<DbOrderModel>[]> {
    return this.firestore
      .collection<DbOrderModel>('orders', (ref) =>
        ref
          .where('sessionId', '==', session.id)
          .where('isConfirmed', '==', true)
          .where('storeId', '==', session.storeId)
      )
      .snapshotChanges();
  }

  public getSessionSeat(sessionId: string, seatId: string | null): Observable<SessionSeatDTO> {
    let guestUserId = this.cookieService.getCookie('guestUserId');
    if (!guestUserId) {
      guestUserId = this.usersService.generateGuestUID();
      this.cookieService.setCookie('guestUserId', guestUserId, 365);
    }
    return this.fns
      .httpsCallable('getSessionSeatV2')({sessionId, seatId, guestUserId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public reservePaidSeat(
    sessionId: string,
    seatId: string | null,
    seatCost: number
  ): Observable<SessionSeatDTO | undefined> {
    return this.fns
      .httpsCallable('reservePaidSeat')({sessionId, seatId, seatCost})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public updateUserSessionHeartbeat(sessionId: string, seatId: string, videoDurationInc: number) {
    const data = {
      lastHeartbeat: Timestamp.now(),
      videoDuration: increment(videoDurationInc),
      timestamp: Date.parse(Timestamp.now().toDate().toISOString()),
    };
    const seatDocRef = this.firestore
      .collection('sessions')
      .doc(sessionId)
      .collection('sessionSeats')
      .doc(seatId);
    return from(seatDocRef.update(data));
  }

  public getBroadcastToken(sessionId: string): Observable<DbSessionBroadcastTokenModel> {
    return this.fns
      .httpsCallable('getBroadcastTokenV2')({sessionId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public endSession(sessionId: string): Observable<{message: string}> {
    return this.fns
      .httpsCallable('stopBroadcast')({sessionId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  @CacheAbleShare()
  public getSessionDataFromFirestore(sessionId: string) {
    return this.firestore.doc<DbSessionModel>(`sessions/${sessionId}`).valueChanges();
  }

  /**
   * Get session data on Entering Session page
   *
   * @param sessionId
   */
  @autoLog('SessionService ~ getSessionDataDTO')
  public getSessionDataDTO(sessionId: string) {
    const session = new Subject<SessionDataDTO>();

    const store = this.storeService.activeStoreDoc$.pipe(
      catchError((e) => {
        this.logService.warn('session-service service ~ getSessionDataDTO ~ store', e);
        return throwError(() => e);
      }),
      retry({count: 3, delay: 300, resetOnSuccess: true}),
      catchError((e) => {
        this.logService.error('session-service service ~ getSessionDataDTO ~ store', e);
        return throwError(() => e);
      })
    );
    const currency = store.pipe(
      switchMap((store) =>
        this.storeService.getStoreCurrency(store.currencyId).pipe(
          map((currency) => {
            return {
              currency: currency?.isoCode,
              paymentSupplier: currency?.paymentSupplier,
            };
          })
        )
      ),
      this.logService.tapLogWithRetry('session-service service ~ getSessionDataDTO ~ currency')
    );
    const cart = this.cartService
      .getUserCart(undefined)
      .pipe(this.logService.tapLogWithRetry('session-service service ~ getSessionDataDTO ~ cart')); // returns user active cart from the service
    const sessionDocData = this.getSessionDataFromFirestore(sessionId).pipe(
      this.logService.tapLogWithRetry(
        'session-service service ~ getSessionDataDTO ~ sessionDocData'
      )
    );
    const shippingMethods = store.pipe(
      switchMap((store) =>
        this.firestore
          .collection<DbStoreShippingMethodModel>(`stores/${store.id}/shippingMethods`, (ref) =>
            ref.where('name', '!=', '')
          )
          .valueChanges()
      ),
      this.logService.tapLogWithRetry(
        'session-service service ~ getSessionDataDTO ~ shippingMethods'
      )
    );
    const liveData = sessionDocData.pipe(
      distinctUntilChanged((a, b) => a?.rtdbInfo?.state === b?.rtdbInfo?.state),
      switchMap((session) =>
        session?.rtdbInfo?.state === 'ACTIVE'
          ? this.rtdb.getSessionRtdbLiveLiveData(session)
          : NEVER
      ),
      this.logService.tapLogWithRetry('session-service service ~ getSessionDataDTO ~ liveData'),
      share({resetOnComplete: false, resetOnError: true, resetOnRefCountZero: true}),
      shareReplay(1)
    );
    this.stateService.themeActive$.next(true);

    const liveProducts = sessionDocData.pipe(
      distinctUntilChanged((a, b) => a?.rtdbInfo?.state === b?.rtdbInfo?.state),
      switchMap((session) =>
        session?.rtdbInfo?.state === 'ACTIVE' ? this.rtdb.getLiveRTDBProducts(session) : NEVER
      ),
      map((products) => {
        // Make sure variant has optionValues property
        products.products.forEach((product: SessionDataProductDTO) => {
          const productData = product.fullProductData;

          if (productData) {
            productData.variants.forEach((variant: SessionDataProductVariantDTO) => {
              this.fixProductOptionValues(variant);
            });

            Object.values(productData.variantsAsMap).forEach(
              (variantAsMap: SessionDataProductVariantDTO) => {
                this.fixProductOptionValues(variantAsMap);
              }
            );
          }
        });

        return products;
      })
    );

    return this.usersService.connectedUser.pipe(
      distinctUntilChanged((a, b) => !!(a === b || (a && b && a.uid === b.uid))),
      switchMap(() => {
        return this.fns
          .httpsCallable<any, SessionDataDTO>('getSessionDataV2')({sessionId})
          .pipe(
            recursiveMapOperator(TimestampHelper.fixAllTimestampsOnValue),

            this.logService.tapLogWithRetry(
              'session-service service ~ getSessionDataDTO ~ liveData'
            ),
            catchError((e) => {
              if ('code' in e && e.code === 'functions/not-found') {
                const storeUrl = this.storeService.getActiveStoreSync()?.url;
                if (!storeUrl) {
                  this.navigationService.goToHomePage().then(null, null);
                  return NEVER;
                }
                this.navigationService.goToStoreUrlWithOptionalParams().then(null, null);
                return NEVER;
              }
              return throwError(() => {
                return e;
              });
            }),
            catchError((e) => this.languageService.translateError(e)),
            switchMap((session) => {
              this.cartService.getUserCart(session.cart);
              const polls = this.pollsService
                .getSessionPolls(sessionId)
                .pipe(
                  this.logService.tapLogWithRetry(
                    'session-service service ~ getSessionDataDTO ~ polls'
                  )
                );
              return combineLatest({
                cart: cart.pipe(
                  startWith(session.cart),
                  throttleTime(1000, undefined, {leading: true, trailing: true})
                ),
                sessionDocData: sessionDocData.pipe(
                  startWith(session),
                  throttleTime(1000, undefined, {leading: true, trailing: true})
                ),
                store: store.pipe(throttleTime(1000, undefined, {leading: true, trailing: true})),
                currency: currency.pipe(
                  startWith({
                    currency: session.currency,
                    paymentSupplier: session.paymentSupplier,
                  }),
                  throttleTime(1000, undefined, {leading: true, trailing: true})
                ),
                liveData: liveData.pipe(
                  filter((data) => !!data),
                  startWith(session.liveData),
                  throttleTime(1000, undefined, {leading: true, trailing: true})
                ),
                polls: polls.pipe(
                  startWith([...session.polls]),
                  throttleTime(1000, undefined, {leading: true, trailing: true})
                ),
                liveProducts: liveProducts.pipe(
                  startWith({products: session.products, productsAsMap: session.productsAsMap})
                ),
                shippingMethods: shippingMethods.pipe(
                  startWith(session.shippingMethods),
                  throttleTime(1000, undefined, {leading: true, trailing: true})
                ),
              }).pipe(
                throttleTime(100, undefined, {leading: true, trailing: true}),
                map((combinedData) => {
                  const nextValue: SessionDataDTO = {...session, ...combinedData.sessionDocData};
                  nextValue.cart = combinedData.cart;
                  if (combinedData.currency.currency) {
                    nextValue.currency = combinedData.currency.currency;
                  }

                  if (combinedData.currency.paymentSupplier) {
                    nextValue.paymentSupplier = combinedData.currency.paymentSupplier;
                  }

                  nextValue.taxName = combinedData.store.taxName;
                  nextValue.taxPercent = combinedData.store.taxPercent;
                  nextValue.liveData = combinedData.liveData;
                  nextValue.products = combinedData.liveProducts.products;
                  nextValue.productsAsMap = combinedData.liveProducts.productsAsMap;
                  nextValue.polls = combinedData.polls;
                  nextValue.shippingMethods = combinedData.shippingMethods;
                  nextValue.hasEnded =
                    nextValue.hasEnded ||
                    combinedData.liveData?.sessionState === SessionState.ended;

                  return nextValue;
                }),
                startWith(session)
              );
            })
          );
      }),
      tap((sessionData) => {
        session.next(sessionData);
      })
    );
  }

  private fixProductOptionValues(variant: SessionDataProductVariantDTO): void {
    variant.optionValues = variant.optionValues ?? {};
    variant.imageUrls = variant.imageUrls ?? [];
  }

  /**
   * Get session data on Entering Session page
   *
   * @param sessionId
   */
  public getSessionDataHomePage(sessionId: string): Observable<SessionDataDTO> {
    return this.usersService.connectedUser.pipe(
      distinctUntilChanged((a, b) => !!(a === b || (a && b && a.uid === b.uid))),
      switchMap(() => {
        return this.fns
          .httpsCallable('getSessionDataV2')({sessionId})
          .pipe(
            recursiveMapOperator(TimestampHelper.fixAllTimestampsOnValue),
            catchError((e) => this.languageService.translateError(e)),
            retry({count: 3, delay: 300, resetOnSuccess: true})
          );
      })
    );
  }

  /**
   *
   * @param sessionId
   */
  public likeSessionToggle(sessionId: string): Observable<any> {
    return this.fns
      .httpsCallable('likeSessionToggleV2')({sessionId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   *
   * @param sessionId
   * @param userId
   */
  @CacheAbleShare()
  public getUserSessionLike(sessionId: string, userId: string) {
    return this.firestore
      .doc<DbSessionLikeModel>(`sessions/${sessionId}/sessionLikes/${userId}`)
      .valueChanges()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  public sendChatMessage(
    sessionId: string,
    message: string,
    trackId: string,
    parentMessage: DbParentMessage | null
  ) {
    return this.fns
      .httpsCallable<unknown, DbSessionMessageModel>('sendSessionMessageV2')({
        sessionId,
        message,
        trackId,
        parentMessage,
      })
      .pipe(catchError((e) => this.languageService.translateError(e)));
  }

  public async moderateChatMessage(data: SessionChatModerationDTO) {
    try {
      const confirmed = await this.appService.showConfirmationModal(
        '',
        this.languageService.translateSync(
          'CONFIRM_MEDIA_DEVICE_SWITCHING.SUB_TITLE_AFTER_DEVICE_NAME'
        ),
        this.languageService.translateSync('CONFIRM_MEDIA_DEVICE_SWITCHING.CONFIRM_TXT')
      );

      if (confirmed) {
        return this.fns
          .httpsCallable<SessionChatModerationDTO, never>('chatModerate')(data)
          .pipe(catchError((e) => this.languageService.translateError(e)));
      } else {
        return EMPTY;
      }
    } catch (_error) {
      return EMPTY;
    }
  }

  public getSessionProductData(
    productId: string,
    sessionId: string
  ): Observable<SessionDataProductDTO> {
    return this.fns
      .httpsCallable('getSessionProductData')({productId, sessionId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  //returns a snapshot of the promo video collection as an array of DbsessionPromoModel objects
  public getPromoVideos(sessionId: string): Observable<QuerySnapshot<DbSessionPromoVideoModel>> {
    return this.firestore
      .collection<DbSessionPromoVideoModel>(`sessions/${sessionId}/sessionPromoVideos`, (ref) =>
        ref.where('isActive', '==', true)
      )
      .get()
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  //returns an object of DbsessionPromoModel
  public getSessionPromoVideo(videoId: string): Observable<DbSessionPromoVideoModel> {
    return this.fns
      .httpsCallable('getSessionPromoVideo')(videoId)
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public updatePlayedVideo(
    sessionId: string,
    video: DbSessionPromoVideoModel
  ): Observable<DbSessionLiveDataModel> {
    return this.fns
      .httpsCallable('updateCurrentVideo')({sessionId, video})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public removeCurrentVideo(sessionId: string): Observable<DbSessionLiveDataModel> {
    return this.fns
      .httpsCallable('removeCurrentVideo')({sessionId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  /**
   *
   * @param sessionId
   * @param productId
   * @param variantId
   */
  public featureSessionProduct(
    sessionId: string,
    productId: string,
    variantId: string
  ): Observable<string> {
    return this.fns
      .httpsCallable('featureSessionProductV2')({sessionId, productId, variantId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }

  public requestOrRemoveSessionReminder(
    sessionId: string,
    sendReminderData: SendReminderData
  ): Observable<string> {
    return this.fns
      .httpsCallable('requestOrRemoveSessionReminder')({sessionId, sendReminderData})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        tap(() => {
          const currentUserId = this.usersService.connectedUserSync.uid;
          this.localStoreService.setItem(sessionId, true, {
            path: `sessionReminders/${currentUserId}`,
          });
        })
      );
  }

  /**
   * Checks for session that are with countDown in store homePage if user has a reminder
   *
   * @param sessionId
   */
  public getUserSessionReminderState(sessionId: string): Observable<boolean> {
    const currentUserId = this.usersService.connectedUserSync.uid;
    try {
      const val = this.localStoreService.getItem<boolean>(
        sessionId,
        `sessionReminders/${currentUserId}`
      );
      if (val !== null) return of(val);
    } catch (_error) {
      // do nothing
    }

    return this.fns
      .httpsCallable('getUserSessionReminderState')({sessionId})
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        tap((val) => {
          const currentUserId = this.usersService.connectedUserSync.uid;
          this.localStoreService.setItem(sessionId, !!val, {
            path: `sessionReminders/${currentUserId}`,
          });
        })
      );
  }

  public updateSessionState(sessionId: string, state: DbSessionLiveDataModel['sessionState']) {
    return this.fns
      .httpsCallable('updateSessionStateV2')({sessionId, state})
      .pipe(retry({count: 3, delay: 300, resetOnSuccess: true}));
  }

  private mappedSessionIdsToRefs = new Map<string, Observable<DatabaseReference | null>>();

  public getSessionCoupons(sessionId: string) {
    return this.firestore
      .doc<DbCouponsDocModel>(`sessions/${sessionId}/data/coupons`)
      .get()
      .pipe(
        map((res) => res.data()),
        take(1),
        retry({count: 3, delay: 300, resetOnSuccess: true}),
        map((res) => {
          if (!res) return res;
          if (res.viewersItemDiscounts) {
            res.viewersItemDiscounts = res.viewersItemDiscounts.filter(
              (x) => JSON.stringify(x) !== '{}' && x.value
            );
          }
          if (res.viewersPercentageDiscounts) {
            res.viewersPercentageDiscounts = res.viewersPercentageDiscounts.filter(
              (x) => JSON.stringify(x) !== '{}' && x.value
            );
          }
          if (res.generalCoupon) {
            res.generalCoupon = {
              coupon: res.generalCoupon.coupon ?? '',
              value: res.generalCoupon.value ?? 0,
            };
          }
          return res;
        })
      );
  }

  public setSessionCoupons(sessionId: string, data: DbCouponsDocModel) {
    return this.firestore.doc(`sessions/${sessionId}/data/coupons`).set(data);
  }

  /**
   * Add session dae to calender or oopen siginModal if user not connected
   *
   * @param session
   */
  public isSendingRemindToServer: boolean;

  public remindSessionToUser(session: {id: string; wasReminded: boolean}) {
    this.isSendingRemindToServer = true;
    this.appService
      .showReminderToggleModal(session.id, session.wasReminded)
      .then((result: unknown) => {
        if (result) {
          const currentUserId = this.usersService.connectedUserSync.uid;
          this.localStoreService.setItem(session.id, true, {
            path: `sessionReminders/${currentUserId}`,
          });
          session.wasReminded = true;
        }
      })
      .finally(() => {
        this.isSendingRemindToServer = false;
      });
  }
}
