import {inject, Injectable} from '@angular/core';
import {AngularFireFunctions} from '@angular/fire/compat/functions';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap'; // eslint-disable-line
import {
  BehaviorSubject,
  combineLatest,
  EMPTY,
  firstValueFrom,
  from,
  interval,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  finalize,
  map,
  retry,
  share,
  shareReplay,
  switchMap,
  take,
  takeWhile,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {LanguageService} from 'src/app/language.service';
import {
  DbParentMessage,
  DbSessionLiveDataModel,
  DbSessionPollModel,
  DbSessionPollUserModel,
  isFeatureEnabled,
  PollResults,
  PollStatus,
  PollType,
  SessionFeature,
} from '../../../../../shared/db-models/session';
import {
  SessionDataDTO,
  SessionDataFullProductDTO,
  SessionDataProductDTO,
  SessionDataProductVariantDTO,
} from '../../../../../shared/dto-models/session-data';
import {AffiliateRegexModel} from '../../../../../shared/utilities/affilaite';
import {PartiallyRequired} from '../../../../../shared/utilities/type-helpers';
import {ConnectedUserModel} from '../../interfaces/users-models';
import {AgoraService} from '../../services/agora/agora-host.service';
import {AppService} from '../../services/app.service';
import {CartService} from '../../services/cart.service';
import {StoresService} from '../../services/stores.service';
import {UsersService} from '../../services/users.service';
import type {SessionPollUserModel} from './session-models';
import {SessionService} from './session.service';

import {LogService} from 'src/app/logger/logger.service';
import {AnalyticsService} from 'src/app/services/analytics.service';
import {NavigateService} from 'src/app/services/navigate.service';
import {PollsService} from 'src/app/services/polls.service';
import {MimicService} from 'src/app/services/sdk/mimic.service';
import {StateHolderService} from 'src/app/services/state-holder.service';
import {DbCartProductModel} from '../../../../../shared/db-models/cart';
import {isPaymentSupplierWithRedirect} from '../../../../../shared/dto-models/payments';
import {SessionState} from '../../../../../shared/types/session';
import {getSessionProductDiscountedPrice} from '../../../../../shared/utilities/calculate-total-cart-price-with-discounts';
import {SessionVideoDurationTracker} from './session-video-duration-tracker';

@Injectable({
  providedIn: 'root',
})
export class SessionFacade {
  private fns = inject(AngularFireFunctions);
  private sessionService = inject(SessionService);
  private usersService = inject(UsersService);
  private appService = inject(AppService);
  private storesService = inject(StoresService);
  private router = inject(Router);
  private navigateService = inject(NavigateService);
  private analytics = inject(AnalyticsService);
  private modalService = inject(NgbModal);
  private agoraService = inject(AgoraService);
  private cartService = inject(CartService);
  protected languageService = inject(LanguageService);
  private stateService = inject(StateHolderService);
  private pollsService = inject(PollsService);
  private mimicService = inject(MimicService);
  private logService = inject(LogService);

  public session$ = this.stateService.session$;
  public currentSessionComponent$ = this.stateService.currentSessionComponent$;
  public user$ = this.usersService.connectedUser;

  public remindSessionToUser = this.sessionService.remindSessionToUser.bind(this.sessionService);
  private videoDurationTracker = new SessionVideoDurationTracker();

  private isBroadcastingSubject = new BehaviorSubject<boolean>(false);
  public isBroadcasting$ = this.isBroadcastingSubject.asObservable();

  public isMobile$ = this.appService.isMobile;
  public cartProducts$ = this.cartService
    .getCurrentSessionCart()
    .pipe(map((cart) => cart?.products ?? []));

  public isHost$: Observable<boolean> = combineLatest({
    session: this.session$.pipe(filter((session) => !!session)),
    user: this.user$,
  }).pipe(map((data) => this.calcIsSessionHost(data.user, data.session)));
  public isLiveSession$ = this.session$.pipe(
    filter((session) => !!session),
    map((session) => this.calcIsLiveSession(session)),
    catchError((e) => {
      this.logService.error('session-facade service ~ isLiveSession$', e);
      return throwError(() => e);
    })
  );

  public readonly activeStore$ = this.storesService.activeStore$;
  public isStoreManager$ = combineLatest({user: this.user$, store: this.activeStore$}).pipe(
    map((data) => !!data.store && !!data.user && data.store.isManager)
  );

  /*
    used to track the activity of a user. if next(true) it means that we force the user to be considered as active
    regardless of the other activities he makes. using next(false) will allow to perform logic on wether the user
    should be consider as active or not.
  */
  public trackUserActivity$ = new Subject<boolean>();
  public triggerAutoCheckout$ = new Subject<void>();
  public openMobileCartTrigger$ = new Subject<void>();

  public polls$: Observable<SessionPollUserModel[]> = combineLatest({
    user: this.user$,
    session: this.session$,
    isHost: this.isHost$,
    isManager: this.isStoreManager$,
  }).pipe(
    switchMap((data) => {
      if (data.session && data.user) {
        return combineLatest(
          data.session?.polls
            .filter((poll) => {
              return data.isHost || data.isManager || data.user?.isAdmin
                ? poll
                : [PollStatus.Active, PollStatus.Locked].includes(poll.status);
            })
            .map((poll: DbSessionPollModel) => {
              return this.pollsService
                .getSessionPollUser(data.session?.id ?? '', poll.id, data.user?.uid ?? '')
                .pipe(
                  map((sessionPollUser: DbSessionPollUserModel | object): SessionPollUserModel => {
                    const answersNum: number =
                      poll.type === PollType.ItemQuestion ? poll.items.length : poll.options.length;

                    const result = {
                      ...poll,
                      isViewed: 'isViewed' in sessionPollUser ? sessionPollUser.isViewed : false,
                      vote: 'vote' in sessionPollUser ? sessionPollUser.vote : null,
                      results:
                        'results' in poll
                          ? poll.results
                          : {
                              ...PollResults.new(answersNum),
                              percentage: PollResults.emptyVoteCounts(answersNum),
                            },
                    };

                    return result;
                  })
                );
            })
        );
      }

      return NEVER;
    }),
    catchError((e) => {
      this.logService.error('session-facade service ~ polls$', e);
      return throwError(() => e);
    })
  );
  public liveData$ = this.session$.pipe(
    map((session) => {
      return session?.liveData;
    })
  );

  public hostBroadcasting$ = this.session$.pipe(
    map((session) => session?.liveData?.sessionState === 'broadcasting'),
    catchError((e) => {
      this.logService.error('session-facade service ~ hostBroadcasting$', e);
      throw e;
    })
  );

  forceSeatReload$ = new BehaviorSubject<boolean>(false);
  public readonly seat$ = combineLatest({
    user: this.user$,
    isHost: this.isHost$,
    reload: this.forceSeatReload$,
  }).pipe(
    withLatestFrom(this.session$),
    map(([data, session]) => {
      return {user: data.user, isHost: data.isHost, session: session, reload: data.reload};
    }),
    distinctUntilChanged((a, b) => {
      const isSameSessionAndUser = !!(
        a.session?.id === b.session?.id && a.user?.uid === b.user?.uid
      );
      return isSameSessionAndUser && !b.reload;
    }),
    concatMap((data) => {
      if (data.isHost || !data.session) return EMPTY;

      const sessionId = data.session.id;
      let seatId: string | null = null;
      try {
        seatId = !data.session.entryFee
          ? localStorage.getItem(this.STORAGE_SESSION_SEAT_IDS + '_' + sessionId)
          : null;
      } catch (_error) {
        // ignore
      }

      return this.sessionService.getSessionSeat(data.session.id, seatId).pipe(
        tap((seat) => {
          try {
            localStorage.setItem(this.STORAGE_SESSION_SEAT_IDS + '_' + sessionId, seat.seatId);
          } catch (_error) {
            // ignore
          }
        })
      );
    }),
    catchError((e) => {
      this.logService.error('session-facade service ~ seat$', e);
      return EMPTY;
    }),
    shareReplay({refCount: true, bufferSize: 1})
  );

  public readonly focusedProduct$ = new ReplaySubject<SessionDataFullProductDTO | null>(1);
  public readonly focusedProductVariant$ = new ReplaySubject<SessionDataProductVariantDTO | null>(
    1
  );
  private readonly STORAGE_SESSION_SEAT_IDS = 'SESSION_SEAT_IDS';
  public featureActive$ = (feature: SessionFeature) =>
    this.session$.pipe(
      map((session) => session?.sessionFeatures ?? []),
      distinctUntilChanged(),
      map((session) => isFeatureEnabled(session, feature)),
      switchMap((isActive) => {
        if (isActive) {
          return of(true);
        }
        return this.theFeatureIsAffiliateAndStoreUsingAffiliate$(feature);
      }),
      catchError((e) => {
        this.logService.error('session-facade service ~ featureActive$', e);
        throw e;
      })
    );

  constructor() {
    const every30sec = interval(30 * 1000);
    every30sec
      .pipe(
        withLatestFrom(this.seat$),
        switchMap(([, seat]) => {
          if (!seat || !this.session$.value?.id) return NEVER;
          const report = this.videoDurationTracker.report();
          return this.sessionService.updateUserSessionHeartbeat(
            this.session$.value.id,
            seat.seatId,
            report?.videoDuration ?? 0
          );
        }),
        catchError((err) => {
          // the error is handled by requesting a new seat by the forceSeatReload$ subject
          this.logService.info(
            'session-facade service ~ constructor ~ every30sec withLatestFrom',
            err
          );
          this.forceSeatReload$.next(true);
          this.forceSeatReload$.next(false);
          return NEVER;
        })
      )
      .subscribe({error: (error) => this.logService.error('session facade ~ every30sec', error)});
    combineLatest([every30sec, this.cartProducts$])
      .pipe(withLatestFrom(this.focusedProduct$), withLatestFrom(this.focusedProductVariant$))
      .subscribe(([[, product], variant]) => {
        if (!product || !variant) return;

        this.loadSessionProductInfo(product.id, variant.id);
      });
    combineLatest([this.seat$, this.stateService.isAgoraPlayingVideo$])
      .pipe(tap(([seat, playing]) => console.debug(`videoDuration combineLatest`, {seat, playing})))
      .subscribe(([seat, isAgoraPlayingVideo]) => {
        if (!seat || !this.session$.value?.id) return;
        const report = this.videoDurationTracker.switch(
          this.session$.value.id,
          seat.seatId,
          isAgoraPlayingVideo
        );
        if (!report) {
          return;
        }
        return this.sessionService.updateUserSessionHeartbeat(
          report.sessionId,
          report.seatId,
          report.videoDuration
        );
      });
    // todo: move to a reasonable place and subscribe only when needed
    this.mimicService.watchCart().subscribe();
  }

  private theFeatureIsAffiliateAndStoreUsingAffiliate$(feature: SessionFeature) {
    if (feature !== 'affiliate') return of(false);
    return this.session$.pipe(
      map((session) => session?.paymentSupplier),
      distinctUntilChanged(),
      map((paymentSupplier) => !!paymentSupplier && isPaymentSupplierWithRedirect(paymentSupplier))
    );
  }

  public clearSessionFacade() {
    this.logService.debug('Clearing session facade');
    this.isCurrentlyInSessionComponent = false;
    this.session$.next(null);
    this.clearProductData();
  }

  public clearProductData() {
    this.focusedProduct$.next(null),
      catchError((e) => {
        this.logService.error('session-facade service ~ clearProductData', e);
        return throwError(() => e);
      });
    this.focusedProductVariant$.next(null);
  }

  private fakeProductData(productId: string, variantId: string | null) {
    const product = this.session$.value?.products.find(
      (product) => product.productId === productId
    );
    if (!product) return this.clearProductData();

    this.clearUnselectableShippingMethods(product);

    this.focusedProduct$.next({
      id: productId,
      name: product.name,
      description: product.shortDescription,
      options: product.optionNames.map((optionName) => {
        return {option: optionName, values: []};
      }),
      shippingMethodIds: product.shippingMethodIds,
      measurementsUrl: '',

      // Customization
      isCustomized: product.isCustomized,
      customType: product.customType,
      customizationText: product.customizationText,
      customizationImage: product.customizationImage,

      variants: [],
      variantsAsMap: {},
      shortDescription: product.shortDescription,
    });
    try {
      return this.focusedProductVariant$.next({
        compareAtPrice: typeof product.compareAtPrice === 'number' ? product.compareAtPrice : null,
        id: variantId!,
        imageUrls: [product.mainImageUrl],
        isOutOfStock: false,
        optionValues: (() => {
          const options = {} as Record<string, string>;
          product.optionNames.forEach((optionName) => {
            options[optionName] = '';
          });
          return options;
        })(),
        orderIndex: 0,
        price: product.price,
        stock: -1,
      });
    } catch (error) {
      this.logService.error('session-facade service ~ fakeProductData', error);
      throw error;
    }
  }

  private calcIsSessionHost(
    user: ConnectedUserModel | null,
    session: PartiallyRequired<SessionDataDTO, 'id'> | null
  ): boolean {
    try {
      return (user && session && session.hostUserId && user.uid === session.hostUserId) === true;
    } catch (error) {
      this.logService.error('session-facade service ~ calcIsSessionHost', error);
      throw error;
    }
  }

  calcIsLiveSession(session: PartiallyRequired<SessionDataDTO, 'id'> | null): boolean {
    try {
      return !!(
        session &&
        session.startTime.toDate() <= new Date() &&
        !session.hasEnded &&
        session.liveData?.sessionState !== 'ended'
      );
    } catch (error) {
      this.logService.error('session-facade service ~ calcIsLiveSession', error);
      throw error;
    }
  }

  clearUnselectableShippingMethods(product: SessionDataProductDTO | SessionDataFullProductDTO) {
    product.shippingMethodIds = product.shippingMethodIds.filter((id) =>
      this.session$.value?.shippingMethods?.find(
        (method) =>
          method.id === id && !!method.name && method.storeId === this.session$.value?.storeId
      )
    );
  }

  private validateSessionState(sessionData: this['session$']['value']) {
    const liveData = sessionData?.liveData;
    const sessionState = liveData?.sessionState ?? null;
    if (
      !liveData ||
      !sessionState ||
      (sessionState !== 'playingPromo' && sessionState !== 'endingPromo')
    ) {
      return;
    }
    const endingTime =
      (liveData.currentPromoVideoStartTime?.toMillis() ?? 0) +
      ((liveData.currentPromoVideoLength ?? 0) + 1.1) * 1000;
    if (endingTime > Date.now()) return;
    this.updateSessionState(sessionData.id, SessionState.paused).subscribe({
      error: (err) => {
        this.logService.error('session-facade service ~ updateSessionState', err);
      },
    });
  }

  private isCurrentlyInSessionComponent = false;
  private lastResultOfSession: SessionDataDTO | null = null;
  public updateSession(sessionId: string) {
    this.logService.log('updating session');

    this.clearSessionFacade();

    this.isCurrentlyInSessionComponent = true;

    if (this.lastResultOfSession?.id === sessionId) {
      return;
    }
    if (this.lastResultOfSession) {
      this.lastResultOfSession.id = sessionId;
    }

    const sessionDataLive = this.sessionService.getSessionDataDTO(sessionId).pipe(
      takeWhile(() => !this.lastResultOfSession || this.lastResultOfSession.id === sessionId),
      filter((data) => data.id === sessionId && this.isCurrentlyInSessionComponent),
      tap((sessionData) => {
        this.lastResultOfSession = sessionData;
      }),
      catchError((e) => {
        this.logService.warn('session-facade service ~ updateSession ~ sessionDataLive', e);
        return throwError(() => e);
      }),
      retry({count: 3, delay: 300, resetOnSuccess: true}),
      catchError((e) => {
        this.logService.error('session-facade service ~ updateSession ~ sessionDataLive', e);
        return throwError(() => e);
      }),
      share()
    );
    sessionDataLive.subscribe((sessionData) => {
      this.session$.next(sessionData);
      if (this.calcIsSessionHost(this.user$.value, sessionData) || this.user$.value?.isAdmin) {
        this.validateSessionState(sessionData);
      }
    });

    sessionDataLive
      .pipe(
        filter((session) => !!session),
        take(1),
        switchMap((sessionData) =>
          this.storesService.getStoreById(sessionData.storeId).pipe(
            map((store) => {
              return {sessionData, storeUrl: store.url};
            })
          )
        ),
        take(1)
      )
      .subscribe((data) => {
        this.storesService.loadActiveStore(data.storeUrl).pipe(take(1)).subscribe();

        if (data.sessionData.liveData?.featuredProductId) {
          this.loadSessionProductInfo(
            data.sessionData.liveData.featuredProductId,
            data.sessionData.liveData.featuredProductVariantId
          );
          return;
        }
        this.clearProductData();
      }),
      catchError((e) => {
        this.logService.error('session-facade service ~ updateSession', e);
        return throwError(() => e);
      });
  }

  //functions from services that we do not need to change
  public get connectedUserSync() {
    return this.usersService.connectedUserSync;
  }
  public readonly openLoginModal = this.usersService.openLoginModal.bind(this.usersService);

  public verifyLoggedInForSessionAction() {
    return this.usersService.verifyLoggedInForSessionAction(this.session$.value);
  }

  public verifyLoggedInForChatMessage() {
    if (!this.session$.value) {
      return throwError(() => new Error('ERROR.NO_SESSION_SELECTED'));
    }
    return this.usersService.verifyLoggedInForChatMessage(this.session$.value);
  }

  public readonly likeSessionToggle = this.sessionService.likeSessionToggle.bind(
    this.sessionService
  );
  public readonly _navigate = this.router.navigate.bind(this.router);
  public readonly navigate = this.navigateService;
  public readonly updateSessionState = this.sessionService.updateSessionState.bind(
    this.sessionService
  );
  public readonly navigateByUrl = this.router.navigateByUrl.bind(this.router);
  public readonly logEvent = this.analytics.logEvent;
  public readonly logout = this.usersService.signOut.bind(this.usersService);
  public readonly openModal = this.modalService.open.bind(this.modalService);
  public readonly hasUserInteracted = this.stateService.hasUserInteracted$;
  public readonly addProductToCart = this.cartService.addProductToCart.bind(this.cartService);
  public readonly uploadCartProductCustomImage = this.cartService.uploadCartProductCustomImage.bind(
    this.cartService
  );
  public readonly updateCartProductCustomValue = this.cartService.updateCartProductCustomValue.bind(
    this.cartService
  );
  public readonly removeProductToCart = this.cartService.removeProductToCart.bind(this.cartService);
  public readonly deleteCustomImageFromStorage = this.cartService.deleteCustomImageFromStorage.bind(
    this.cartService
  );

  public sendChatMessage(message: string, trackId: string, parentMessage: DbParentMessage | null) {
    return from(this.verifyLoggedInForChatMessage()).pipe(
      take(1),
      switchMap(() =>
        this.sessionService.sendChatMessage(
          this.session$.value!.id,
          message,
          trackId,
          parentMessage
        )
      )
    );
  }
  public readonly featureSessionProduct = this.sessionService.featureSessionProduct.bind(
    this.sessionService
  );
  public readonly getSessionProductPrice = getSessionProductDiscountedPrice.bind(
    this.sessionService
  );
  public readonly removeCurrentVideo = this.sessionService.removeCurrentVideo.bind(
    this.sessionService
  );
  public readonly getHostBroadcastToken = this.agoraService.getHostBroadcastToken.bind(
    this.agoraService
  );
  getAffiliateRegexModel(): Observable<AffiliateRegexModel | undefined> {
    try {
      return this.sessionService.getAffiliateRegexModel(this.activeStore$);
    } catch (error) {
      this.logService.error('session-facade service ~ getAffiliateRegexModel', error);
      throw error;
    }
  }

  public readonly isLanguageRtl = this.languageService.isLanguageRtl.bind(this.languageService);

  public showReminderToggleModal = this.appService.showReminderToggleModal.bind(this.appService);
  public updateProductQuantityInCart(product: DbCartProductModel, quantity: number) {
    try {
      const updateResults = this.cartService.updateProductQuantityInCart(
        product.cartId,
        product.id ?? '',
        quantity
      );
      return updateResults;
    } catch (error) {
      this.logService.error('session-facade service ~ updateProductQuantityInCart', error);
      throw error;
    }
  }

  public clearCartDataAfterCheckout() {
    if (this.lastResultOfSession) this.updateSession(this.lastResultOfSession.id);
  }

  public async startBroadcast() {
    this.updateSessionState(this.session$.value!.id, SessionState.broadcasting).subscribe({
      error: (err) => {
        this.logService.error('session-facade service ~ updateSessionState', err);
      },
    });
    if (this.session$.value && (await firstValueFrom(this.isHost$))) {
      await this.agoraService.startBroadcast(this.session$.value.id, this.session$.value.storeId);
    }
    this.isBroadcastingSubject.next(true);
  }

  public async joinChannelAsAudience() {
    const seat = await firstValueFrom(this.seat$);
    if (!this.session$.value || !seat?.broadcastToken || !seat.broadcastTokenUid) return;
  }

  public stopVideoBroadcast(state: DbSessionLiveDataModel['sessionState']) {
    const sessionId = this.session$.value?.id;
    if (!sessionId) return;

    combineLatest([
      this.updateSessionState(sessionId, state),
      this.agoraService.stopVideoBroadcast(sessionId),
    ]).subscribe({
      next: () => {
        this.isBroadcastingSubject.next(false);
      },

      error: (err) => {
        this.logService.error('session-facade service ~ stopVideoBroadcast', err);
      },
    });
  }
  public async leaveChannel() {
    await this.agoraService.leaveChannel().catch((e) => {
      this.logService.error('session-facade service ~ leaveChannel', e);
      throw e;
    });
  }

  public endSession() {
    if (this.session$.value?.id) {
      this.sessionService
        .endSession(this.session$.value.id)
        .pipe(switchMap(() => this.agoraService.stopVideoBroadcast(this.session$.value!.id)))
        .subscribe({
          next: () => {
            location.reload();
          },
          error: (err) => {
            this.logService.error('session-facade service ~ endSession', err);
            location.reload();
          },
        });
    }
  }
  /**
   * ends promotion video state by sending relevant state to the DbSessionLiveData
   *
   * @returns
   */
  public endPromotionVideo() {
    if (!this.session$.value) return;

    this.sessionService
      .updateSessionState(this.session$.value.id, SessionState.playingPromo)
      .subscribe({
        error: (err) => {
          this.logService.error('session-facade service ~ endPromotionVideo', err);
        },
      });
  }

  /**
   *  For host and admin we set the focused product as the current feature item
   */
  public setHostItem() {
    // For host and admin We set the focused product as the current feature item
    //this.isSendingToServer = true;
    // Set host and admin selected feature Item
    if (!this.session$.value) {
      return;
    }
    const session = this.session$.value;

    combineLatest({product: this.focusedProduct$, variant: this.focusedProductVariant$})
      .pipe(
        take(1),
        switchMap(({product, variant}) => {
          if (!product || !variant) {
            return EMPTY;
          }
          return this.sessionService.featureSessionProduct(session.id, product.id, variant.id);
        }),
        finalize(() => {
          //this.isSendingToServer = false;
        })
      )

      .subscribe({
        next: () => {
          // message === 'o.k';
        },
        error: (err) => {
          this.logService.error('session-facade service ~ setHostItem', err);
        },
      });
  }
  /**
   * Get sessionProduct from DB
   *
   * @param id
   * @param productId
   * @param variantId
   */
  public loadSessionProductInfo(productId: string, variantId: string | null = null) {
    if (!this.session$.value) return of(null);
    const sessionId = this.session$.value.id;

    this.fakeProductData(productId, variantId);

    const product =
      this.session$.value.products.find((product) => product.productId === productId)
        ?.fullProductData ?? null;

    if (!product) return this.focusedProduct$.pipe(take(1));

    product.options.sort((a, b) => {
      if (a.option < b.option) return -1;

      if (a.option > b.option) return 1;

      return 0;
    });

    // Handle if featured item has specific variant id to show
    if (variantId) {
      this.setFocusedVariant(product.variantsAsMap[variantId] ?? product.variants[0]);
    } else {
      this.setFocusedVariant(product.variants[0]);
    }

    if (sessionId === this.session$.value.id && this.isCurrentlyInSessionComponent) {
      this.clearUnselectableShippingMethods(product);
      this.focusedProduct$.next(product);
    }
    return this.focusedProduct$.pipe(take(1));
  }

  /**
   * Sets the focused product options and image
   *
   * @param variant
   */
  public setFocusedVariant(variant: SessionDataProductVariantDTO) {
    this.focusedProductVariant$.next(variant);
  }

  public voteToPoll(sessionId: string, pollId: string, vote: string): Observable<string> {
    return this.fns
      .httpsCallable('pollsAddUserVote')({
        sessionId,
        pollId,
        vote,
      })
      .pipe(
        catchError((e) => this.languageService.translateError(e)),
        retry({count: 3, delay: 300, resetOnSuccess: true})
      );
  }
}
